* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #0d0d0e;
  color: aqua;
}

html {
  scroll-behavior: smooth;
}

.btn-pri {
  background-color: #708283;
  color: #fff;
  border-radius: 10px;
}

.btn-cus {
  background: #b85042;
  color: #fff;
  border-radius: 3px;
  box-shadow: -11.09px 18.13px 36.6483px rgba(242, 153, 74, 0.13);
}

.mt-h {
  margin-top: 7rem;
}

@media only screen and (max-width: 600px) {
  .rmt-1 {
    margin-top: 1rem;
  }
  .rmt-2 {
    margin-top: 2rem;
  }
  .rmt-3 {
    margin-top: 3rem;
  }
  .rmt-5 {
    margin-top: 5rem;
  }
  .rtext-center {
    text-align: center;
  }
}
