.showcase {
  background-image: url("../../assets/images/bg0.png");
  height: calc(86.5vh + 114px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .cus-nav {
    background-color: transparent;
    color: #fff;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), transparent);

    .si {
      height: 40px;
    }

    .link-style {
      color: #fff !important;
      margin-left: 1rem;
      font-weight: bold;
    }
    .logo {
      height: 80px;
      border-radius: 21px;
    }
  }

  .showcase-content {
    height: 86.5vh;
    position: absolute;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);

    .showcase-title {
      font-size: 5rem;
      font-weight: bold;
      color: #fff;
      text-align: center;
    }

    .tit {
      color: white;
      font-weight: bold;
    }
    .showlogo {
      height: 50px;
    }
  }

  .linkh {
    color: #666;
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
  }
  .linkh:after {
    display: block;
    content: "";
    border-bottom: solid 3px #fff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  .linkh:hover:after {
    transform: scaleX(1);
  }
  .linkh.fromRight:after {
    transform-origin: 100% 50%;
  }
  .linkh.fromLeft:after {
    transform-origin: 0% 50%;
  }
}

.expicon {
  height: 30px;
}

.title {
  font-weight: bold;
  font-size: 2.6rem;
  color: #fff;
}

.subtitle {
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.about {
  margin-top: 10rem;
}

.slider {
  margin-top: 10rem;
  .slider-div {
    margin: 0 !important;
    padding: 0 !important;
    .slider-img {
      height: 285px;
    }
  }
}

.story {
  margin-top: 10rem;
}

.content-title {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roadmap {
  margin-top: 10rem;
  .per-text {
    color: aqua;
  }
}

.team {
  margin-top: 10rem;

  .team-img {
    border-radius: 20px;
  }
  .social-icon {
    height: 35px;
  }
}

.faq {
  margin-top: 5rem;

  .con-bot {
    font-weight: 500;
    color: #e7e8d1;
    font-size: 1.6rem !important;
  }
}

.footer {
  .footer-text {
    font-weight: bold;
  }
}

.minus,
.plus {
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  border: 3px solid #fff;
}

.plus-img {
  height: 60px;
  cursor: pointer;
}

.display-number {
  color: #fff;
  height: 70px;
  width: 70px;
}
.number {
  font-weight: bold;
  font-size: 4rem;
}

@media only screen and (max-width: 600px) {
  .showcase {
    height: auto !important;
    .showcase-content {
      position: relative !important;
      height: auto !important;
      margin-top: 2rem !important;

      .mbcon {
        margin-top: 2rem !important;
      }
    }
  }

  .slider-img {
    height: 60.5px;
  }

  .team-img {
    height: auto !important;
  }

  .rmt {
    margin-top: 5rem;
  }

  .countd {
    align-items: center !important;
  }

  .showcase-title {
    font-size: 1rem !important;
  }

  .showcase-subtitle {
    font-size: 1rem !important;
  }
}
